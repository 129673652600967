let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Mac Patterson",
    "role": "Software Engineer with a love for Data Analytics",
    "linkedinId":"mac-patterson-86908415",
    "roleDescription": "I like dabbling in various parts of development, full stack so everything from sever side tools to frontend, and like to learn about new technologies or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/mac-patterson-86908415/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"youtube",
          "url":"https://www.youtube.com/@durangomac",
          "className":"fa fa-youtube"
        },
        {
          "name":"github",
          "url":"https://www.github.com/durangomac",
          "className":"fa fa-github"
        }
      ],
    "aboutme":"Spent 16 years in the aviation industry working for two different airlines and decided to take a career break but it's time to get back into the work force. I have expereince with many technologies ranging from server side tooling, web services, data anlaytics and frontend web development. I believe everyone has a passion in their life and for me it's making sure to bring something bright into your life and others on a daily basis, even just a warm smile can make a huge difference.",
    "address":"Seattle, WA",
    "website":"https://www.macpatterson.com",
    "education":[
      {
        "UniversityName":"Fort Lewis College",
        "specialization":"Bachelors of Arts - Computer Science Information Systems",
        "MonthOfPassing":"July",
        "YearOfPassing":"2004",
        "Achievements":"Voluntering for the student orgizations The Independtet - Weekly Student Newspaper and Association of Students of Fort Lewis College - Court Member."
      }
    ],
    "work":[
      {
        "CompanyName":"Alaska Airlines",
        "specialization":"Manager, Crew Operations Planning",
        "MonthOfLeaving":"May",
        "YearOfLeaving":"2022",
        "Achievements":"Led a team of planning analysts overseeing the creation of pilot and flight attendent schedules and the bidding and awarding process of those schedules."
      },
      {
        "CompanyName":"Alaska Airlines",
        "specialization":"Senior Software Engineer",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2021",
        "Achievements":"Part of the Data Services team with responsibilities for the Enterprise Data Warehouse, Data Analytics software systems and associated systems. Many projects over the course of the five years covering most of the departments including revenue, flight operations, crew, guest services, loyality and employee records. Includes being the Tech Lead for DevSecOps, Tableau and ALteryx servers, flight operations data and guest survey data."
      },
      {
        "CompanyName":"SkyWest Airlines",
        "specialization":"Software Engineer II",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2016",
        "Achievements":"Worked on many projects over the nine years including data warehouse, data analytics systems, flight oprations, crew management, web applications, server tools, third party integration systems and being level 2 support for mission critical systems."
      }
    ],
    "skillsDescription":"Main Skils",
    "skills":[
      {
        "skillname":"SQL"
      },
      {
        "skillname":"Python"
      },
      {
        "skillname":"Database"
      },
      {
        "skillname":"Analytics"
      },
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"Mac is a wealth of knowledge in both technical and business area. Very quick at picking up new technologies as well as understanding already existing systems, loves working on innovative projects, POCs.",
        "name":"Corina Darcozy - Senior Software Egnineer - Alaska Airlines"
      },
      {
        "description":"Mac is a dedicated, resourceful and loyal employee. He continually looks for ways to improve the process and will speak up if something does not seem right. His passion motivates not only him but those around him.",
        "name":"Susan Ewbank - Progam and Events - Seafair Charitable Foundation"
      },
      {
        "description":"Always found the most economic and efficient solutions to problems. He was very knowledgeable about his area, the business and the industry.",
        "name":"Mike Thompson - Retired COO - SkyWest Airlines"
      },
      {
        "description":"He's tremendously insightful, analytical and has a great sense of strategic choices and impact. His communication skills are outstanding. He’s outgoing, hard working and shows great initiative.",
        "name":"Ryan Di Giovanni - Manager Pilot Crew Scheduling"
      },
      {
        "description":"Not only is he a team player, but he is a skilled leader that truly cares for others and we can always rely on him to lead with confidence. Even in tough situations, he always displays his resilience and finds ways to help others.",
        "name":"Alice Tam - Principal Partnerships Codeshare & Interline - Alaska Airlines"
      },
      {
        "description":"From the front end to the back end, Mac is able to quickly grasp and work with the increasing complexities of the full-stack development process from start to finish. Not only is he an accomplished and talented technical leader, but Mac is an incredible human being as well who is a joy to work with.",
        "name":"Heather Harris - Field Cheif Data Analytic Officer - Alteryx"
      },
      {
        "description":"He was always very innovative and continually looked for ways to improve existing applications which are qualities that will translate to any industry.",
        "name":"Justin Esplin - VP - Information Technology - SkyWest, Inc."
      }
    ]
  }
  
  export default resumeData