import React, { Component } from 'react';
export default class YouTube extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <footer>
      <div className="row">
        <div className="twleve columns videoWraper">
        <iframe width="560" height="315" src="https://www.youtube.com/embed?listType=playlist&list=UULF-Hu4kU5CcmBxe74IEtu7WQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a></div>
      </div>
    </footer>
    );
  }
}